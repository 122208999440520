// src/components/Login.js
import React, { useState } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const phoneNumberPattern = /^[+]?[1-9]\d{1,14}$/;

const Login = () => {
  const [success, setsuccess] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useAuth(); 
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {

    if (!phoneNumberPattern.test(phoneNumber)) {
      setError('Invalid phone number format.');
    }else{
    try {
      const userDocRef = doc(db, 'users', phoneNumber);
      const docSnap = await getDoc(userDocRef);

      if (!docSnap.exists() || docSnap.data().password !== password) {
        setError('Invalid phone number or password.');
      }else
      {setsuccess('Logged in successfully');
      setUser({ phoneNumber });
        navigate('/dashboard');
        return
    }
    } catch (err) {
      setError('Failed to log in. Please try again.');
    }
  }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Fleet Login</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">{success}</div>}
      <form style={{ maxWidth: '400px', margin: '0 auto' }}>
        <div className="mb-3">
          <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            className="form-control"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number in E.164 format (e.g., +1234567890)"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Password</label>
          <input
            type="password"
            id="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            required
          />
        </div>
        <button type="button" style={{ width: '100%' }} onClick={handleLogin} disabled={!phoneNumber || !password} className="btn btn-primary">Login</button>
      </form>
    </div>
  );
};

export default Login;
import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebaseConfig';
import { collection, query, getDocs, updateDoc } from 'firebase/firestore';
import { doc, getDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
const Dashboard = () => {
  const { user, logout } = useAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sucess, setsucess] = useState(false);
  const [getdata, setGetData] = useState(true);
  const [error, setError] = useState('');
  var css = `.table-container {
    width: 100%; /* Adjust based on your requirement */
    height: 400px; /* Adjust based on your requirement */
    overflow: scroll; /* Enables scrolling */
    position: relative; /* Establishes a positioning context */
}

table {
    width: 100%;
    border-collapse: collapse; /* Optional: for styling */
}

thead th {
    position: sticky;
    top: 0; /* Stick to the top of the table */
    background-color: #fff; /* Ensures the header is not transparent */
    z-index: 10; /* Keeps the header above other content */
}
*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
tbody td, thead th {
    padding: 10px;
    border: 1px solid #ccc;
}

/* Optional: Styles to improve the appearance */
table {
    min-width: 600px; /* Ensures table is wider than the container */
}

th, td {
    min-width: 120px; /* Set minimum width for columns */
}
`
  const handleOpenDialog2 = (e,id) => {
    MySwal.fire({
      title: 'Enter Odometer Reading',
      input: 'text', // Set input type
      inputLabel: 'Odometer (in km)',
      inputPlaceholder: 'Enter your odometer reading',
      inputAttributes: {
        'aria-label': 'Type your odometer reading here'
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      preConfirm: (reading) => {
        if (!reading || isNaN(reading)) {
          Swal.showValidationMessage("Please enter a valid number");
        }
        return reading;
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        updateDoc(doc(db, `users/${userData.phoneNumber}/records/${new Date(selectedDate).getDate()}-${new Date(selectedDate).getMonth()}-${new Date(selectedDate).getFullYear()}/data`,id), {
          odometer: result.value
        }).then(() => {
          setsucess("Odometer reading added successfully");
          e.disabled = true;
        }).catch((error) => {
          setError(error.message);
        });
      }
    });
  };
  const handleOpenDialog = (e, id) => {
    MySwal.fire({
      title: 'Enter Consumption Details',
      html: `
        <input id="liters" class="swal2-input" placeholder="Liters consumed" type="text">
        <input id="cost" class="swal2-input" placeholder="Cost in INR" type="text">
      `,
      confirmButtonText: 'Submit',
      focusConfirm: false,
      preConfirm: () => {
        const liters = document.getElementById('liters').value;
        const cost = document.getElementById('cost').value;
        
        // Check if both inputs are numbers
        if (isNaN(liters) || liters.trim() === "" || isNaN(cost) || cost.trim() === "") {
          Swal.showValidationMessage("Please enter valid numbers for both liters and cost."); // Show error message
          return false; // Stop the promise if validation fails
        }
        return { liters: liters, cost: cost };
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        const recordDate = `${new Date(selectedDate).getDate()}-${new Date(selectedDate).getMonth() + 1}-${new Date(selectedDate).getFullYear()}`;
        const docPath = `users/${userData.phoneNumber}/records/${recordDate}/data`;
  
        updateDoc(doc(db, docPath, id), {
          fuelinamount: result.value.cost,
          fuelinltr: result.value.liters,
        }).then(() => {
          setsucess("Consumption details added successfully");
          e.target.disabled = true;
        }).catch((error) => {
          setError(error.message);
        });
      }
    });
  };
  
useEffect(() => {
  setTimeout(() => {
    setSelectedDate(`${new Date().getFullYear()}-${String("0"+(new Date().getMonth()+1)).slice(-2)}-${new Date().getDate()-1}`);  
    document.getElementById("dateInput").value = `${new Date().getFullYear()}-${String("0"+(new Date().getMonth()+1)).slice(-2)}-${new Date().getDate()-1}`
  },1000)
},[])
  useEffect(() => {
    const fetchUserData = async () => {
        const userRef = doc(db, 'users', user.phoneNumber);
        try {
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setUserData(userSnap.data());
          } else {
            setError('No user data found.');
          }
        } catch (err) {
          setError('Failed to fetch user data.');
        }
        setLoading(false);
      }

    if(user){
      fetchUserData();
    }
    else{
      setLoading(false);
      setError('No user data found.');
      setTimeout(() => {
        logout();
      }, 3000);
    }
  }, []);
  const [selectedDate, setSelectedDate] = useState('');
  const [recordss, setrecordss] = useState([]);

  useEffect(() => {
      if (selectedDate !== '') {
          if(getdata){
            const fetchData = async () => {
              var date = new Date(selectedDate);
                const recordssRef = collection(db, `users/${userData.phoneNumber}/records/${date.getDate()}-${date.getMonth()}-${date.getFullYear()}/data`);
                const q = query(recordssRef);
                
                try {
                    const querySnapshot = await getDocs(q);
                    const fetchedrecordss = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        vehname: doc.data().vehname,
                        odometer: doc.data().odometer,
                        fuelinamount: doc.data().fuelinamount,
                        dailydistance: doc.data().dailydistance,
                        lastUpdate: doc.data().lastUpdate.toDate().toString().replace(" GMT+0530 (India Standard Time)","")
                    }));
                    if(fetchedrecordss.length=== 0){
                      setError('No data found');
                      setsucess("")
                    }else{
                      setError('');
                      setsucess('Data fetched successfully');
                    }
                    setrecordss(fetchedrecordss);
                    setGetData(false);
                } catch (error) {
                    console.error("Error fetching recordss: ", error);
                }
            };
            fetchData();
          }
      }
  }, [selectedDate]);
  useEffect(() => {
    if(error){
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  },[error])
  useEffect(() => {
    if(sucess){
      setTimeout(() => {
        setsucess('');
      }, 3000);
    }
  },[sucess])
  const handleDateChange = (records) => {
      setGetData(true);
      setrecordss([]);
      setSelectedDate(records.target.value);
  };
  if (loading) return <div>Loading...</div>;

  return (
    <div className="container mt-5">
      <h2>Dashboard</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      {sucess && <div className="alert alert-success">{sucess}</div>}
      <div >
        <style>{css}</style>
           <div className="d-flex">
           <label htmlFor="dateInput" className='form-label' style={{ marginTop: '10px',fontWeight: 'bold'}}>Choose a date:</label>
            <input
                type="date"
                id="dateInput"
                className="form-control" 
                value={selectedDate}
                onChange={handleDateChange}
                style={{ marginLeft: '10px',width: "200px"}}
            />
           </div>
            <br />
            <br />
            <div className="table-container">
            {recordss.length > 0 && (
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Vehicle Number</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Date</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Daily Distance</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Fuel</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold',boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', backgroundColor: '#c2c0c4'}}>Odometer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {recordss.map((records) => (
                            <tr key={records.id}>
                                <td style={{ textAlign: 'center' }}>{records.vehname}</td>
                                <td style={{ textAlign: 'center' }}>{records.dailydistance}</td>
                                <td style={{ textAlign: 'center' }}>{records.lastUpdate}</td>
                                <td style={{ textAlign: 'center' }}><button className={(typeof records.fuelinamount === "string")?"btn btn-primary disabled":"btn btn-primary"} onClick={(e) => handleOpenDialog(e.target,records.id)}>Add Fuel data</button></td>
                                <td style={{ textAlign: 'center' }}><button className="btn btn-primary" disabled={!records.odometer===null} onClick={(e) => handleOpenDialog2(e.target,records.id)}>Odometer</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
      </div>
      <br />
      <button onClick={logout} className="btn btn-danger">Logout</button>
    </div>
  );
};

export default Dashboard;
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCuP0ud51142NMZhN4wNW1bppYPiDCTRU4",
    authDomain: "gps-fleet-app.firebaseapp.com",
    projectId: "gps-fleet-app",
    storageBucket: "gps-fleet-app.appspot.com",
    messagingSenderId: "550284132816",
    appId: "1:550284132816:web:6e699544a0adfe4c4eb9d5",
    measurementId: "G-QQXJRBCFH6"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);


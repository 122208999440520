// src/context/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const phoneNumber = user?.phoneNumber;

        if (phoneNumber) {
          const userDocRef = doc(db, 'users', phoneNumber);
          const docSnap = await getDoc(userDocRef);
          
          if (docSnap.exists()) {
            setUser(docSnap.data());
          } else {
            setError('User data not found.');
          }
        }
      } catch (error) {
        setError('Error fetching user data.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const login = async (phoneNumber, password) => {
    try {
      const userDocRef = doc(db, 'users', phoneNumber);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists() && docSnap.data().password === password) {
        setUser({ phoneNumber });
        navigate('/dashboard');
      } else {
        setError('Invalid phone number or password.');
      }
    } catch (error) {
      setError('Error logging in. Please try again.');
    }
  };

  const logout = () => {
    setUser(null);
    navigate('/');
  };

  const contextValue = {
    user,
    loading,
    setUser,
    error,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
